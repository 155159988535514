import React from 'react';
import './mentions-legales.css'

function Legal() {
    return (
        <div className={"legal"}>
            <div className="legal-loader">
                <p>Mentions Légales</p>
            </div>
            <div className={"content"}>
                <h1>Mentions légales</h1>
                <h2>Identité</h2>
                <p>Nom du site web : Flam Info<br/>
                    Adresse : <a href="https://www.flaminfo.fr/">https://www.flaminfo.fr/</a><br/>
                    Propriétaire : Paul Flammarion<br/>Responsable de publication : Paul Flammarion<br/>
                Conception et réalisation : Paul Flammarion</p>
                    <p>Hébergement :&nbsp;<br/><br/></p>
                        <div className={"hebergement"}>
                            <p><strong>Flam Info</strong><br/>Auto-Entreprise<br/>RCS Versailles 895 138 576 00015<br/>Siège social : 65 rue d'Estienne d'Orves – 78220 Viroflay – France.</p>
                        </div>
                <h2>Personne morale</h2>
                <p><strong>Flam Info</strong><br/>Auto-Entreprise<br/>RCS Versailles 895 138 576 00015<br/>Siège social : 65 rue d'Estienne d'Orves – 78220 Viroflay – France.</p>
                <h2>Conditions d’utilisation</h2>
                <p>L’utilisation du présent site implique l’acceptation pleine et entière des conditions générales d’utilisation décrites ci-après. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment.</p>
               <h2>Propriété intellectuelle</h2>
                <p>Sauf mention contraire, tous les éléments accessibles sur ce site restent la propriété exclusive de Flam Info, en ce qui concerne les droits de propriété intellectuelle ou les droits d’usage.</p>
                <p>Il est donc interdit pour un usage autre que privé ou pédagogique de reproduire, vendre, diffuser, modifier, publier intégralement ou partiellement des éléments du site, sauf autorisation écrite au préalable par Flam Info.</p>
                <p>Toute exploitation non autorisée du site ou d’un quelconque élément qu’il contient est considérée comme constitutive d’une contrefaçon et peut être poursuivie en justice.</p>
                <h2>Liens</h2>
                <p>Flam Info décline toutes responsabilités relatives aux autres sites redirigés par les liens présents sur ce site, notamment en ce qui concerne l’exactitude ou la fiabilité des informations, des données et des opinions qui y figurent.</p>
                <h2>Confidentialité</h2>
                <p>Tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une preuve d’identité.</p>
            </div>
        </div>
    );
}

export default Legal;
