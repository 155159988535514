import React from 'react';
import './contact.css';
import worldMap from '../../assets/images/world-map.png'

    function Contact() {
        function moveImg(event) {
            var ecran =  window.innerWidth;
            var imgWidth= 2200;
            var sourie = event.pageX;
            var image = document.getElementById("map");
            var center = ecran / 2;
            var translation = imgWidth - ecran;

            if (sourie < center && ecran > 600){
                image.style.left= -translation +"px";
            }
            if (sourie > center && ecran > 600){
                image.style.left= 0+"px";
            }

        }
        return (
                <div className={"contact"} onMouseMove={moveImg}>
                <div className="contact-loader">
                   <p>Contact</p>
                </div>
                <div className={"content"}>
                    <h1>Me contacter</h1>
                    <h2>Email</h2>
                    <p>Vous pouvez me contacter via <a href={"mailto:paul@flaminfo.fr"}>paul@flaminfo.fr</a>, je vous donnerai une réponse dans les meilleurs délais.</p>
                    <h2>Téléphone</h2>
                    <p>Il est également possible de me joindre par téléphone au <a href={"tel:0652180117"}>06 52 18 01 17</a>.</p>
                    <div  className={"game"}>
                        <img src={worldMap} id='map'/>
                    </div>
            </div>
            </div>
    );
}

export default Contact;
