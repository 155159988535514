import React  from "react";
import logo from '../../assets/images/logo-white.png';
import './header.css';

function Header(){
    return(
        <div className="header">
            <a href={"/"}><img src={logo} alt="Logo"/></a>
            <div className="nav-bar">
                <a href={"/site"} className="underline"><div className="button">Sites</div></a>
                <a href={"/sysadmin"} className="underline"><div className="button">SysAdmin</div></a>
                {/*<a href={"/devis"} className="underline"><div className="button">Devis</div></a>*/}
                <a href={"/contact"} className="underline"><div className="button">Contact</div></a>

            </div>


            <div className="hamburger-menu">
                <input id="menu__toggle" type="checkbox"/>
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span/>
                </label>
                <ul className="menu__box">
                    <li><a className="menu__item" href="/site">Sites</a></li>
                    <li><a className="menu__item" href="/sysadmin">SysAdmin</a></li>
                    <li><a className="menu__item" href="/contact">Contact</a></li>
                </ul>
            </div>


        </div>



    )
}

export default Header;
