import React from 'react';
import './sysadmin.css';


function Sysadmin() {
    return (
        <div className={"sysadmin"}>
            <div className="sysadmin-loader">
                <p>SysAdmin</p>
            </div>
            <div className={"content"}>
                <h1>Administation système</h1>
                <h2>Mes Services</h2>
                <li>Maintenance de systèmes</li>
                <li>Réalisation de backups et de solutions de récupérations</li>
                <li>Assurer la compabilité des applications hébergées sur le système</li>
                <li>Installation de plugins et mises à jour régulières</li>
                <li>Gestion de comptes</li>
                <h2>Maintenance Système</h2>
                <p>Flam Info est responsable de la bonne accessibilité et disponibilité du système.<br/>
                Je suis aussi là pour corriger les problèmes qui pourraient compromettre l'accès ou les performances du système.</p>
               <h2>Réalisation de backups et de solutions de récupérations</h2>
                <p>La perte de données n'est pas envisageable pour vous.<br/> C'est pourquoi je fais des backups ma
                priorité.<br/> Pour cela je mettrai en place des sauvegardes régulières et une vérification de
                celles-ci.</p>
                <h2>Assurer la compabilité des applications hébergées sur le système</h2>
                <p>Chaque application a sa spécificité. Une fois développé en local il faut pouvoir la déployer
                sur l'un de vos serveurs.<br/> Cette configuration n'est pas toujours anodine.<br/> Je vous propose donc
                de faire ce travail à votre place pour que vous puissiez vous concentrer sur l'essentiel.</p>
               <h2>Installation de plugins et mises à jour régulières</h2>
               <p>Un serveur nécessite des mises à jour régulières pour pouvoir être sécurisé et optimisé.<br/>
               Je vous propose donc de prendre en charge la mise à jour de votre système, en fonction de vos besoins.<br/>
               Bien évidemment, si certains plugins sont nécessaires au bon fonctionnement de l'application, je veillerai particulièrement
               à leur bonne mise en place.</p>
               <h2>Gestion de comptes</h2>
                <p>Pour vous garantir la main sur votre application, je vous donnerai un accès privilégié, à vous et votre
                équipe, à l'entièreté de l'application.<br/> Je peux aussi mettre en place un système de droits pour donné un
                accès restreint à certaines personnes de votre groupe.</p>
                <div className={"site-contact"}>
                    <p>Pour effectuer un devis, merci de me <a href={"/contact"}>contacter</a> par mail à <a href={"mailto:paul@flaminfo.fr"}>paul@flaminfo.fr</a></p>
                </div>

            </div>
        </div>
    );
}

export default Sysadmin;
