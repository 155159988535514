import React from 'react';
import './test.css'
import test from '../assets/images/world-map.png'


function Test() {
    function moveImg(event) {
        var x = event.clientX;
        var shark = document.getElementById("shark");
        shark.style.left = x+'px';
    }
    return (
        <div onMouseMove={moveImg}>
            <img src={test} id='shark'/>
        </div>
    );
}

export default Test;
