import React from 'react';
import './site.css';
import css3 from '../../assets/images/css3.svg'
import  html5 from '../../assets/images/html5.svg'
import react_logo from '../../assets/images/react_logo.svg'
import js_logo from '../../assets/images/js_logo.svg'
import nodejs_logo from '../../assets/images/nodojs_logo.svg'

const images = [html5, css3, js_logo, react_logo, nodejs_logo];
const delay = 2500;
function Site() {
        const [index, setIndex] = React.useState(0);
        const timeoutRef = React.useRef(null);

        function resetTimeout() {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }

        React.useEffect(() => {
            resetTimeout();
            timeoutRef.current = setTimeout(
                () =>
                    setIndex((prevIndex) =>
                        prevIndex === images.length - 1 ? 0 : prevIndex + 1
                    ),
                delay
            );

            return () => {
                resetTimeout();
            };
        }, [index]);

    return (
        <div className={"site"}>
            <div className="site-loader">
                <p>Sites Internet</p>
            </div>
            <div className={"content"}>
                <h1>La Conception de Sites Internet</h1>
                    <li>Que vous ayez un cahier des charges précis ou juste une idée, faites appel à Flam Info !</li>
                    <li>Bénéficiez d'un service sur-mesure, car Flam Info est disponible et à votre écoute.</li>
                    <li>Profitez des dernières innovations technologiques grâce à une conception de votre site tournée vers le futur.</li>
                <h2>Mes Technologies Web</h2>





                <div className="slideshow">
                    <div
                        className="slideshowSlider"
                        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                    >
                        {images.map((val, index) => (
                            <div className="slide" key={index}>
                                <img src={val}/>
                            </div>
                        ))}
                    </div>

                    <div className="slideshowDots">
                        {images.map((_, idx) => (
                            <div
                                key={idx}
                                className={`slideshowDot${index === idx ? " active" : ""}`}
                                onClick={() => {
                                    setIndex(idx);
                                }}
                            ></div>
                        ))}
                    </div>
                </div>

                <div className={"line"}/>
                <h2>Un Site Responsive</h2>
                <p>De nos jours, il n'est plus acceptable de ne pas avoir un site qui fontionne sur la totalité des appareils.</p>
                <p>C'est pour cela, que dès son développement, le site sera pensé et créé pour fonctionner sur téléphone et sur grand écran,
                afin de proposer à l'utilisateur une expérience inoubliable.</p>
                <div className={"line"}/>
                <h2>Une Avancée avec le Client</h2>
                <p>Il n'est pas toujours facile de visualiser le rendu d'un site internet avant sa conception.</p>
                <p>C'est pourquoi, je vais vous faire part de l'avancée du développement, pour que vous
                puissiez modifier votre demande en temps réel.</p>
                <div className={"line"}/>
                <div className={"site-contact"}>
                    <p>Pour effectuer un devis, merci de me <a href={"/contact"}>contacter</a> par mail à <a href={"mailto:paul@flaminfo.fr"}>paul@flaminfo.fr</a></p>
                </div>
            </div>
        </div>
    );
}

export default Site;
