import React from "react";
import profil from '../../assets/images/profil.jpg';
import homeLoading from '../../assets/images/home-loading.png'
import './home.css';

export default function Home() {
    return (
            <div className="home">
                <div className="home-loader">
                    <img src={homeLoading} className="home-img-loader"/>
                </div>
                <div className="webdev">
                <h1>Paul Flammarion</h1>
                <h2>&lt;Développeur/&gt;</h2>
                    <p className={"first-p"}><strong>Flam Info</strong> est une auto-entreprise spécialisée dans le numérique.
                    Je suis réactif et fiable pour vous livrer vos commandes dans le
                    meilleur délai.</p>
                <p>Toutes vos demandes sont 100% personnalisables.</p>
                </div>
                <div className="home-choice">
                    <div className="grid">
                        <div className="sites-button">
                            <div className="checkbox">
                            <input type="checkbox" id="checkbox_1"/>
                            <label htmlFor="checkbox_1"><div className="close-button">&#10006;</div><div className="title-button">Sites Internet</div> </label>
                            <p>La conception de site internet est l'une de mes spécialités.
                                Je peux vous faire des sites vitrines comme des plateformes
                                de e-commerce.
                                <br/>
                                La conception à partir de vos
                                modèles est tout à fait possible, ainsi il sera
                                exactement comme vous en avez envie.
                                <br/>
                                <a href="/site">En savoir plus</a> </p>
                        </div>
                        </div>
                        <div className="server-button">
                            <div className="checkbox">
                        <input type="checkbox" id="checkbox_2"/>
                        <label htmlFor="checkbox_2"><div className="close-button">&#10006;</div><div className="title-button">SysAdmin</div> </label>
                        <p>Que ce soit un départ de zéro ou la maintenance d'un système,
                            mon entreprise est là pour vous.
                            <br/>
                            Je suis familiarisé
                            avec la majorité des systèmes d'exploitation, comme
                            évidemment les machines tournant sous Linux.
                            <br/>
                            <a href="/sysadmin">En savoir plus</a> </p>
                    </div>
                        </div>
                    </div>
                </div>
                <div className={"presentation"}>
                <h2>Qui suis je?</h2>
                <img src={profil} className={"img-profil"}/>
                <p>Jeune étudiant en école d'informatique, j'ai pu apprendre à faire du
                    développement de sites internet mais aussi à me familiariser avec différents environnements,
                    tel que Linux et la configuration de serveur.</p>
                </div>
            </div>
        );
}
