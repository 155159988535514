import React from 'react';
import './devis.css';


function Devis() {
    return (
        <div className={"devis"}>

            <div className="devis-loader">
                <p>Devis</p>
            </div>
            <div className={"content"}>
                <h1>Page en développement, veuillez prendre contact avec nous sur la page <a href={"/contact"}>contact</a>. </h1>
            </div>
        </div>
    );
}

export default Devis;
