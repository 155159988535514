import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Footer from "./components/footer/Footer";
import Contact from "./pages/contact/Contact";
import Test from "./components/Test";
import Devis from "./pages/devis/Devis";
import Site from "./pages/site/Site";
import Sysadmin from "./pages/sysadmin/Sysadmin";
import Legal from "./pages/mentions-legales/MentionsLegales";

function App() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/test" element={<Test/>}/>
                <Route path="/devis" element={<Devis/>}/>
                <Route path="/site" element={<Site/>}/>
                <Route path="/sysadmin" element={<Sysadmin/>}/>
                <Route path={"/mentions-legales"} element={<Legal/>}/>
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;